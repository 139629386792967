<template>
  <div
    :class="{ dark: section.dark_mode }"
    class="component no-padding c-featured padding-t-24 bp-768:padding-t-48"
  >
    <div class="row large padding-x-24">
      <common-header :headline="section.headline" :sponsor="section.sponsor" />
    </div>
    <div v-if="$screen.laptop" class="c-featured__desktop position-relative">
      <div
        class="
          c-featured__background-wrapper
          position-absolute
          top-0
          left-0
          width-100
          height-100
          z-index-1
          display-flex
        "
      >
        <div class="c-featured__background--empty height-100"></div>
        <div
          v-bind:style="{
            'background-image':
              'url(' +
              (action_shot_image ? action_shot_image.path : null) +
              ') ',
          }"
          class="height-100 c-featured__background--image"
        ></div>
      </div>
      <div class="row large z-index-3 position-relative padding-x-24">
        <div class="display-flex width-100">
          <div class="c-featured__players">
            <ul class="reset-list">
              <li
                class="c-featured__players--item"
                :class="{
                  'is-active': selected_player_index === index,
                }"
                @click="selectPlayer(index)"
                v-for="(player, index) in section.selectedPlayers"
                :key="player.id"
              >
                <div
                  class="c-featured__players--image"
                  v-bind:style="{
                    'background-image':
                      'url(' +
                      (player.image
                        ? player.image + '?width=540'
                        : $root.dark_mode
                        ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
                        : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg') +
                      ') ',
                  }"
                ></div>
              </li>
            </ul>
          </div>
          <div class="c-featured__player--wrapper position-relative">
            <div class="c-featured__player" v-if="selectedPlayer">
              <div class="c-featured__player--header">
                <div
                  v-if="selectedPlayer.jersey"
                  class="
                    c-featured__player--number
                    font-weight-700 font-size-30
                    line-height-125
                  "
                >
                  {{ selectedPlayer.jersey }}
                </div>
                <div
                  class="
                    c-featured__player--name
                    padding-x-12
                    line-height-125
                    font-weight-700
                  "
                >
                  <div
                    class="font-size-18"
                    v-html="selectedPlayer.first_name"
                  ></div>
                  <div class="font-size-30">{{ selectedPlayer.last_name }}</div>
                </div>
              </div>
              <div class="c-featured__player--content display-flex">
                <div
                  class="c-featured__player--image flex-shrink-0"
                  v-bind:style="{
                    'background-image':
                      'url(' +
                      (selectedPlayer.image
                        ? selectedPlayer.image + '?width=540'
                        : $root.dark_mode
                        ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
                        : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg') +
                      ') ',
                  }"
                ></div>
                <div class="c-featured__player--info flex-item-1">
                  <ul class="reset-list">
                    <li v-if="selectedPlayer.position_short">
                      <span>Position:</span>
                      <span>
                        {{ selectedPlayer.position_short }}
                      </span>
                    </li>
                    <li v-if="selectedPlayer.academic_year">
                      <span>Class:</span>
                      <span>
                        {{ selectedPlayer.academic_year }}
                      </span>
                    </li>
                    <li v-if="selectedPlayer.height">
                      <span>Height:</span>
                      <span>
                        {{ selectedPlayer.height }}
                      </span>
                    </li>
                    <li
                      v-if="
                        selectedPlayer.gender != 'F' && selectedPlayer.weight
                      "
                    >
                      <span> Weight: </span>
                      <span>
                        {{ selectedPlayer.weight }}
                      </span>
                    </li>
                    <li v-if="selectedPlayer.hometown">
                      <span>Hometown:</span>
                      <span>
                        {{ selectedPlayer.hometown }}
                      </span>
                    </li>
                  </ul>
                  <div class="c-featured__player--bottom">
                    <button
                      class="
                        c-featured__player--arrow
                        c-common__arrow-alt c-common__arrow
                      "
                      @click="selectPlayer(selected_player_index - 1)"
                      :disabled="selected_player_index < 1"
                    >
                      <feather size="24px" type="arrow-left"></feather>
                    </button>
                    <a
                      class="
                        s-player-stats__bio
                        margin-x-12
                        s-common__button s-common__button-large
                        s-player-stats__bio--vertical
                        text-decoration-none
                      "
                      :href="
                        '/roster.aspx?rp_id=' +
                        (selectedPlayer ? selectedPlayer.rp_id : '')
                      "
                      target="_blank"
                    >
                      <span class="s-player-stats__bio-text"
                        >View Full Bio</span
                      >
                    </a>
                    <button
                      class="
                        c-featured__player--arrow
                        c-common__arrow-alt c-common__arrow
                      "
                      @click="selectPlayer(selected_player_index + 1)"
                      :disabled="
                        selected_player_index >=
                        section.selectedPlayers.length - 1
                      "
                    >
                      <feather size="24px" type="arrow-right"></feather>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!$screen.laptop" class="position-relative c-featured__mobile">
      <div class="c-featured__player--wrapper position-relative">
        <div class="c-featured__player--image-wrapper" v-if="selectedPlayer">
          <div
            class="c-featured__player--image flex-shrink-0"
            v-bind:style="{
              'background-image':
                'url(' +
                (selectedPlayer.image
                  ? selectedPlayer.image + '?width=190'
                  : $root.dark_mode
                  ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
                  : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg') +
                ') ',
            }"
          ></div>
        </div>
        <div class="c-featured__players">
          <ul class="reset-list">
            <li
              class="c-featured__players--item"
              :class="{
                'is-active': selected_player_index === index,
              }"
              @click="selectPlayer(index)"
              v-for="(player, index) in section.selectedPlayers"
              :key="player.id"
            >
              <div
                class="c-featured__players--image"
                v-bind:style="{
                  'background-image':
                    'url(' +
                    (player.image
                      ? player.image + '?width=80'
                      : $root.dark_mode
                      ? 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_dark.jpg'
                      : 'https://s3.amazonaws.com/assets.sidearmsports.com/common/images/card-default_light.jpg') +
                    ') ',
                }"
              ></div>
            </li>
          </ul>
        </div>
        <div class="c-featured__player" v-if="selectedPlayer">
          <div class="c-featured__player--header">
            <div
              v-if="selectedPlayer.jersey"
              class="
                c-featured__player--number
                font-weight-700 font-size-30
                line-height-125
              "
            >
              {{ selectedPlayer.jersey }}
            </div>
            <div
              class="
                c-featured__player--name
                padding-x-12
                line-height-125
                font-weight-700
              "
            >
              <div
                class="font-size-18"
                v-html="selectedPlayer.first_name"
              ></div>
              <div class="font-size-30">{{ selectedPlayer.last_name }}</div>
            </div>
          </div>
          <div class="c-featured__player--content">
            <div class="c-featured__player--info flex-item-1">
              <ul class="reset-list">
                <li v-if="selectedPlayer.position_short">
                  <span>Position:</span>
                  <span>
                    {{ selectedPlayer.position_short }}
                  </span>
                </li>
                <li v-if="selectedPlayer.academic_year">
                  <span>Class:</span>
                  <span>
                    {{ selectedPlayer.academic_year }}
                  </span>
                </li>
                <li v-if="selectedPlayer.height">
                  <span>Height:</span>
                  <span>
                    {{ selectedPlayer.height }}
                  </span>
                </li>
                <li
                  v-if="selectedPlayer.gender != 'F' && selectedPlayer.weight"
                >
                  <span> Weight: </span>
                  <span>
                    {{ selectedPlayer.weight }}
                  </span>
                </li>
                <li v-if="selectedPlayer.hometown">
                  <span>Hometown:</span>
                  <span>
                    {{ selectedPlayer.hometown }}
                  </span>
                </li>
              </ul>
              <div class="c-featured__player--bottom display-flex">
                <button
                  class="
                    c-featured__player--arrow
                    c-common__arrow-alt c-common__arrow
                  "
                  @click="selectPlayer(selected_player_index - 1)"
                  :disabled="selected_player_index < 1"
                >
                  <feather size="24px" type="arrow-left"></feather>
                </button>
                <a
                  class="
                    s-player-stats__bio
                    margin-x-12
                    s-common__button s-common__button-large
                    s-player-stats__bio--vertical
                    text-decoration-none
                  "
                  :href="
                    '/roster.aspx?rp_id=' +
                    (selectedPlayer ? selectedPlayer.rp_id : '')
                  "
                  target="_blank"
                >
                  <span class="s-player-stats__bio-text">View Full Bio</span>
                </a>
                <button
                  class="
                    c-featured__player--arrow
                    c-common__arrow-alt c-common__arrow
                  "
                  @click="selectPlayer(selected_player_index + 1)"
                  :disabled="
                    selected_player_index >= section.selectedPlayers.length - 1
                  "
                >
                  <feather size="24px" type="arrow-right"></feather>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import SectionBase from "./SectionBase";
import CommonHeader from "@/components/common/CommonHeader.vue";
export default {
  extends: SectionBase,
  name: "SectionPlayers",
  components: { CommonHeader },
  data: () => ({
    selected_player_index: 0,
    action_shots: [],
  }),
  methods: {
    selectPlayer(index) {
      this.selected_player_index = index;
      this.get_actionshots();
    },
    get_actionshots() {
      Axios({
        method: "GET",
        url: "/services/roster_xml.aspx",
        params: {
          rp_id: this.selectedPlayer.id,
          format: "json",
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      }).then((response) => {
        if (response && response.data && response.data.action_shots) {
          this.action_shots = response.data.action_shots;
        }
      });
    },
  },
  computed: {
    action_shot_image() {
      return this.action_shots.length > 0 ? this.action_shots[0] : null;
    },
    selectedPlayer() {
      return this.section.selectedPlayers.length > 0
        ? this.section.selectedPlayers[this.selected_player_index]
        : null;
    },
  },
  mounted() {
    this.get_actionshots();
  },
};
</script>

<style scoped lang="scss">
.c-featured__desktop {
  .c-featured {
    background-size: cover;
    background-position: top center;
    &__players {
      flex: 0 1 458px;
      max-height: 878px;
      overflow: auto;
      margin: 48px 0;
      background: $main-color;
      padding: 18px;
      border: 1px solid $main-border-color;
      box-sizing: border-box;
      border-radius: 10px;
      &--item {
        flex: 0 1 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 12px;
        &.is-active {
          .c-featured__players--image {
            &:before {
              content: "";
            }
          }
        }
      }
      &--image {
        width: 150px;
        border-radius: 50%;
        height: 150px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        background-size: 100%;
        background-position: top center;
        transition: 0.25s;
        &:hover {
          background-size: 110%;
          transition: 0.25s;
        }
        &:before {
          content: none;
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: $color-primary;
          opacity: 0.5;
        }
      }
      ul {
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__background {
      &--empty {
        width: 38.125%;
      }
      &--image {
        width: 61.875%;
        position: relative;
        background-size: cover;
        background-position: top center;
        z-index: 1;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.8);
        }
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            90deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 70%
          );
        }
      }
    }
    &__player {
      max-width: 595px;
      width: 100%;
      flex-shrink: 0;
      &--background {
        background-size: cover;
        background-position: top center;
        z-index: 1;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.8);
        }
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            90deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 50.05%
          );
        }
      }
      &--arrows {
        display: flex;
        align-items: center;
      }
      &--arrow {
      }
      &--header {
        display: flex;
        border-bottom: 1px solid $main-border-color;
      }
      &--info {
        padding-left: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &--bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      }
      &--content {
        padding: 24px 0;
        color: $main-font-color;
        ul {
          li {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
            span:first-child {
              font-size: 1rem;
              line-height: 150%;
              font-weight: 700;
            }
          }
        }
      }
      &--name {
        padding: 12px 0;
      }
      &--image {
        width: 280px;
        border-radius: 50%;
        height: 280px;
        overflow: hidden;
        position: relative;
        background-size: 100%;
        background-position: top center;
      }
      &--number {
        width: 80px;
        background: $color-primary;
        color: $text-on-primary;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--wrapper {
        flex: 1 1 auto;
        padding: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.c-featured__mobile {
  .c-featured {
    &__players {
      width: 100%;
      display: flex;
      border-top: 1px solid $main-border-color;
      border-bottom: 1px solid $main-border-color;
      overflow: auto;
      background: $main-color-alt;
      padding: 24px 12px;

      &--item {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 12px;
        &.is-active {
          .c-featured__players--image {
            &:before {
              content: "";
            }
          }
        }
      }
      &--image {
        width: 60px;
        border-radius: 50%;
        height: 60px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        background-size: 100%;
        background-position: top center;
        transition: 0.25s;
        &:hover {
          background-size: 110%;
          transition: 0.25s;
        }
        &:before {
          content: none;
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: $color-primary;
          opacity: 0.5;
        }
      }
      ul {
        display: flex;
        justify-content: center;
      }
    }
    &__player {
      padding: 12px;
      &--background {
        background-size: cover;
        background-position: top center;
        z-index: 1;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.8);
        }
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            90deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 50.05%
          );
        }
      }
      &--arrows {
        display: flex;
        align-items: center;
      }
      &--arrow {
      }
      &--header {
        display: flex;
        border-bottom: 1px solid $main-border-color;
      }
      &--info {
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        ul {
          min-height: 170px;
        }
      }
      &--bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      }
      &--content {
        color: $main-font-color;
        ul {
          li {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
            span:first-child {
              font-size: 1rem;
              line-height: 150%;
              font-weight: 700;
            }
          }
        }
      }
      &--name {
        padding: 12px 0;
      }
      &--image {
        width: 190px;
        border-radius: 50%;
        height: 190px;
        overflow: hidden;
        position: relative;
        background-size: 100%;
        background-position: top center;
        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px;
        }
      }
      &--number {
        width: 80px;
        flex-shrink: 0;
        background: $color-primary;
        color: $text-on-primary;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--wrapper {
      }
    }
  }
}
</style>

<style lang="scss">
.dark {
  .c-featured__desktop {
    .c-featured__background--image {
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 70%);
      }
    }
  }
}
</style>
